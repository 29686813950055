import React, {useState, useEffect} from "react";
import {FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaPhone, FaYoutube,} from "react-icons/fa";
import {FaXTwitter} from "react-icons/fa6";
import FooterLink from "Components/FooterLink";
import {connect} from 'react-redux';
import {generalInteraction} from "../../modules/analytics";
import {isAgencyAdmin, isAgencyApproved, isAgencyUser, isAdminSupportOrFinance} from "../../modules/auth";
import {getVersions} from "../../services/versionService";
import {} from "../../modules/permissions";


const VersionDisplay = () => {
    const shouldCheck = window.EDGE_VERSION !== 'dev';

    const [versions, setVersions] = useState({portal: false});
    useEffect(() => {
        const fetchVersions = async () => {
            if (shouldCheck) {
                const {result, error} = await getVersions();
                // console.log('checking footer version')
                if (result) {
                    setVersions(result);
                } else {
                    console.error('Error fetching versions:', error);
                }
            }
        };

        void fetchVersions();
    }, []); // Note the empty array here, this means the effect will only run once after the initial render of the component

    if (!shouldCheck || versions.portal === false ) {
        return null;
    }
    return <span><span>A: {versions.api}</span> | <span>P: {versions.portal}</span></span>
};

function Footer({isVenue, isAgency, loggedIn, generalInteraction, isAgencyApproved, isAgencyUser, isAgencyAdmin, isAdminSupportOrFinance}) {
    return (
        <div>
            <div className="bg-green">
                <div className="container py-4">
                    <div className="row">
                        <div
                            className="text-base col col-w-full md:col-w-1/2 lg:col-w-1/3 xxl:col-w-1/6 text-center md:text-left mb-3 md:mb-0 items-center justify-start flex text-lg">
                            <FaPhone className="text-dark-green mr-4"/>
                            <div onClick={() => {
                                generalInteraction({type: "general_footer_edge_phone_click"})
                            }}>
                                <a href="tel:441780484051" className="text-black font-semibold">
                                    +44(0) 1780 484051
                                </a>
                            </div>
                        </div>
                        <div
                            className="text-base col col-w-full md:col-w-1/2 lg:col-w-1/3 xxl:col-w-1/6 text-center md:text-left items-center justify-start flex text-lg">
                            <FaEnvelope className="text-dark-green mr-4"/>
                            <div onClick={() => {
                                generalInteraction({type: "general_footer_edge_email_click"})
                            }}>
                                <a
                                    href="mailto:info@edgevenues.com"
                                    className="text-black font-semibold"
                                >
                                    info@edgevenues.com
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="bg-black mt-auto">
                <div className="container pt-10 md:pb-14 text-off-white lg:flex font-body text-sm">
                    <div className="row w-full">
                        <div className="col col-w-1/2 md:col-w-1/3 lg:col-w-1/6 mb-12 md:mb-0">
                            <h5 className="font-body mb-2 text-sm font-semibold">
                                BOOK EVENTS ONLINE
                            </h5>
                            <a href={window.WORDPRESS_URL} className="text-off-white block mb-1">
                                Home / Quick Search
                            </a>
                            <FooterLink
                                href="/advanced-search?results=13326&back_projection=0&exhibition=0&location_type=Map&radius=24140.16&sortBy=Capacity&types_text=Hotels&types=1&types=2&types=3&types=4&types=5&types=6&types=7&types=8&types=9&types=10&types=11&types=12&types=13">
                                Advanced Search
                            </FooterLink>
                            <FooterLink href="/uk-venue-locations">UK Locations</FooterLink>
                            <FooterLink href="/worldwide-venue-locations">
                                Worldwide Locations
                            </FooterLink>
                            <FooterLink href="/special-offers">Special Offers</FooterLink>
                            <FooterLink href="/the-word">Industry Insights</FooterLink>
                            {isVenue && (
                                <FooterLink href="/your-venues">Your Venues</FooterLink>
                            )}
                            <FooterLink href="/help">Knowledge Hub</FooterLink>
                            <a
                                href="mailto:data@edgevenues.com"
                                className="text-off-white block mb-1"
                            >
                                Suggest a Venue
                            </a>
                        </div>
                        <div className="col col-w-1/2 md:col-w-1/3 lg:col-w-1/6 mb-12 md:mb-0">
                            <div className="sm:pl-8 md:pl-0">
                                <h5 className="font-body mb-2 text-sm font-semibold">
                                    INDUSTRY INSIGHTS
                                </h5>
                                <FooterLink href="/edge-venue-news">Venue News</FooterLink>
                                <FooterLink href="/edge-venue-blog">Venue Blog</FooterLink>
                                <a href={`${window.WORDPRESS_URL || ''}/the-word`} className="text-off-white block mb-1">
                                    Insights
                                </a>
                                {/*<FooterLink href={`${window.WORDPRESS_URL || ''}/the-word`}>Insights</FooterLink>*/}
                                <FooterLink href="/inspiration">Inspiration</FooterLink>
                                <FooterLink href="/community">Community</FooterLink>
                                <FooterLink href="/voice">Voice</FooterLink>
                            </div>
                        </div>
                        {(isAgency && isAgencyApproved) && (
                            <div className="col col-w-1/2 md:col-w-1/3 lg:col-w-1/6 mb-12 md:mb-0">
                                <h5 className="font-body mb-2 text-sm font-semibold">
                                    AGENCY EDGE
                                </h5>
                                <>
                                    <FooterLink href="/commission-offers">
                                        Commission Offers
                                    </FooterLink>
                                    <FooterLink href="/agency-rates">Agency Rates</FooterLink>
                                    <FooterLink href="/fam-trips">FAM Trips</FooterLink>
                                    <FooterLink href="/movers-and-shakers">
                                        Movers and Shakers
                                    </FooterLink>
                                    <FooterLink href="/renovation-news">
                                        Renovation News
                                    </FooterLink>
                                    <FooterLink href="/edge-venue-news">Venue News</FooterLink>
                                </>
                            </div>
                        )}
                        <div className="col col-w-1/2 md:col-w-1/3 lg:col-w-1/6 mb-12 md:mb-0">
                            <div className={`${(isAgency && isAgencyApproved) ? "sm:pl-8 md:pl-0" : ""}`}>
                                <h5 className="font-body mb-2 text-sm font-semibold">
                                    CORPORATE
                                </h5>
                                <FooterLink href="/about">About us</FooterLink>
                                <FooterLink href="/contact">Contact</FooterLink>
                                <FooterLink href="/privacy">Privacy Policy</FooterLink>
                                <FooterLink href="/cookies">Cookies</FooterLink>
                                <FooterLink href="/disclaimer">Disclaimer</FooterLink>
                                <FooterLink href="/terms-and-conditions">
                                    Terms and Conditions
                                </FooterLink>
                                <FooterLink href="/platform-fee-terms-and-conditions">
                                    Platform Fee Terms and Conditions
                                </FooterLink>
                            </div>
                        </div>
                        <div className="col col-w-1/2 md:col-w-1/3 lg:col-w-1/6 mb-12 md:mb-0">
                            <div className={!isAgency ? "sm:pl-8 md:pl-0" : ""}>
                                <h5 className="font-body mb-2 text-sm font-semibold">
                                    YOUR EDGE
                                </h5>
                                {loggedIn && (
                                    <>
                                        <FooterLink href="/admin/my-edge">My EDGE</FooterLink>
                                        <FooterLink href="/logout">Log Out</FooterLink>
                                        {isAgencyUser || isAgencyAdmin || isAdminSupportOrFinance ? <FooterLink href={'/support-desk'} target={'_blank'}>Edge Support Desk</FooterLink> : null}
                                    </>
                                )}
                                {!loggedIn && (
                                    <>
                                        <FooterLink href="/register">Register</FooterLink>
                                        <FooterLink href="/login">Sign In</FooterLink>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col col-w-full md:col-w-1/3 lg:col-w-1/6 text-center md:text-left">
                            <a href="https://www.facebook.com/EDGEvenues" className="mr-4" onClick={() => {
                                generalInteraction({type: "general_footer_edge_facebook_click"})
                            }}>
                                <FaFacebookF className="text-2xl"/>
                            </a>
                            <a href="https://x.com/EDGEvenues" className="mr-4" onClick={() => {
                                generalInteraction({type: "general_footer_edge_twitter_click"})
                            }}>
                                <FaXTwitter className="text-2xl"/>
                            </a>
                            <a href="https://www.linkedin.com/company/edge-venues" className="mr-4" onClick={() => {
                                generalInteraction({type: "general_footer_edge_linkedin_click"})
                            }}>
                                <FaLinkedinIn className="text-2xl"/>
                            </a>
                            <a href="https://www.instagram.com/edgevenues/" className="mr-4" onClick={() => {
                                generalInteraction({type: "general_footer_edge_instagram_click"})
                            }}>
                                <FaInstagram className="text-2xl"/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCmR5MeqMf6tGXuWWZrZEZNQ" className="mr-4" onClick={() => {
                                generalInteraction({type: "general_footer_edge_youtube_click"})
                            }}>
                                <FaYoutube className="text-2xl"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="container pt-6 pb-8 md:pb-4">
                    <p className="text-grey-darker text-xs md:text-sm mb-0">
                        Copyright ©2020 EDGE Venues Ltd. All rights reserved. EDGE Venues is
                        registered in England under company number 12060838. VAT number: GB
                        331425927
                        <span className={'float-right'}><VersionDisplay /></span>
                    </p>
                </div>
            </footer>
        </div>
    );
}

export default connect(state => ({
    isAgencyApproved: isAgencyApproved(state),
    isAgencyUser: isAgencyUser(state),
    isAgencyAdmin: isAgencyAdmin(state),
    isAdminSupportOrFinance: isAdminSupportOrFinance(state),
}), {generalInteraction})(Footer);
