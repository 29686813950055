import {
  hasLicensedVenues,
  hasVenues,
  canEditVenue,
  canEditUsers,
  canCreateVenue,
  isInternal,
  isRolePlatformAdmin,
  isMarketingManager,
  canEditRoles,
  isRoleAccountManager,
  canSeeSubscriptions,
  canCreateSubscriptions,
  isRoleEdgeAdmin,
  canReadChecksheet,
  canAdministerAgencies,
  isAgencyAdmin,
  isCompanyAdmin,
  hasCorporateAccount,
  isHeadOfProcurement,
  isHeadOfEvents,
  isEventSpecialist,
  isCompanyUser,
  isAgencyUser,
  isNonEventSpecialist,
  hasActiveCorporateAccount,
  hasCorporateAccess,
  hasCorporateKpiDocument,
  canAccessCorporateCalendar,
  canAccessCorporateLocations,
  canAccessEdgeTeamUpdates,
  canAccessCorporateDocuments,
  isIndividualUserType,
  canHaveCorporateAccounts,
  isEdgeSupport,
  isEdgeFinance, isAgencyUserType, hasAnyVenueWithAnyPermission, hasAnyVenueWithPermission,
} from "./permissions";
import { isAgency, isAgencyApproved } from "./auth";
import * as CORPORATE_ROUTES from "../Router/corporate_routes";
import * as VENUE_ROUTES from "../Router/venue_routes";
import { PERMISSIONS } from "../constants";
import * as ROUTES from "../Router/corporate_routes";

const everybody = () => true;

const items = [
    {
        text: 'My EDGE',
        path: '/admin/my-edge',
        authTest: everybody,
    },
    {
        text: 'Venues',
        path: '/admin/venues',
        authTest: auth => isRolePlatformAdmin(auth) ||  hasVenues(auth) || isEdgeSupport(auth) ,
    },
    {
        text: 'Agency',
        path: CORPORATE_ROUTES.FRONTEND.AGENCY.ALL,
        authTest: auth => (isAgencyAdmin(auth) || isRolePlatformAdmin(auth) || isEdgeFinance(auth) || isEdgeSupport(auth) ),
    },
    {
        text: 'Your RFPs',
        path: '/admin/your-rfps',
        authTest: auth => {
            return !isInternal(auth) && !isRolePlatformAdmin(auth) && !isAgencyUser(auth) && !isAgencyUserType(auth) && !isAgencyAdmin(auth) && hasVenues(auth) && canReadChecksheet(auth)
        },
    },
    {
        text: 'Subscriptions',
        path: '/admin/subscriptions',
        authTest: auth => canSeeSubscriptions(auth),
    },
    {
        text: 'Site Visits',
        path: '/admin/site-visits',
        authTest: auth => isIndividualUserType(auth) || isInternal(auth) || isAgencyUser(auth) || isCompanyUser(auth),
    },
    {
        text: 'Edge Plus',
        path: '/admin/edge-plus',
        authTest: auth => hasAnyVenueWithAnyPermission(auth,[
          PERMISSIONS.CAN_BE_INCLUDED_IN_NEWS,
          PERMISSIONS.CAN_BE_INCLUDED_IN_BLOGS,
          PERMISSIONS.CAN_BE_INCLUDED_IN_SPECIAL_OFFERS,
        ]) && (isMarketingManager(auth) || isInternal(auth) || isRoleAccountManager(auth)),
    },
    {
        text: 'Agency Edge',
        path: '/admin/agency-edge',
        authTest: auth => (isMarketingManager(auth) || isInternal(auth) || isRoleAccountManager(auth)) && hasAnyVenueWithAnyPermission(auth,[
          PERMISSIONS.CAN_BE_INCLUDED_IN_COMMISSION_DEALS,
          PERMISSIONS.CAN_BE_INCLUDED_IN_FAM_VISITS,
          PERMISSIONS.CAN_BE_INCLUDED_IN_MOVERS_AND_SHAKERS,
          PERMISSIONS.CAN_BE_INCLUDED_IN_RENOVATION_NEWS,
          PERMISSIONS.CAN_BE_INCLUDED_IN_AGENCY_RATES,
        ]),
    },
    {
        text: 'Users',
        path: '/admin/users',
        authTest: auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth)
    },
    {
        text: 'Analytics',
        path: '/admin/analytics',
        authTest: auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth) || hasAnyVenueWithPermission(auth, PERMISSIONS.CAN_BE_INCLUDED_IN_SITE_ANALYTICS),
    },
    // {
    //     text: 'Analytics',
    //     path: '/admin/analytics-stats',
    //     authTest: auth => hasVenues(auth) && !hasLicensedVenues(auth),
    // },
    {
        text: 'Content Management',
        path: '/admin/content-management',
        authTest: auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth),
    },
    {
        text: 'RFP',
        path: '/admin/rfp',
        authTest: auth => ((isAgencyUser(auth) || isAgencyAdmin(auth)) && isAgencyApproved(auth)) || isRolePlatformAdmin(auth) || isEdgeFinance(auth),
    },
    {
        text: 'Corporate',
        path: '/admin/corporate-accounts',
        authTest: auth => (isRoleEdgeAdmin(auth))  || isEdgeSupport(auth) || ((isAgencyUser(auth) || isAgencyAdmin(auth)) && canHaveCorporateAccounts(auth)) || (isCompanyAdmin(auth) || isEventSpecialist(auth) || isHeadOfEvents(auth) || isHeadOfProcurement(auth) || isNonEventSpecialist(auth)) && (hasActiveCorporateAccount(auth) && hasCorporateAccess(auth)),
    },
    {
        text: 'Events',
        path: '/admin/events',
        authTest: auth =>  ((isAgencyUser(auth) || isAgencyAdmin(auth)) && isAgencyApproved(auth))  || isRolePlatformAdmin(auth) || isEdgeFinance(auth)
    },
    {
        text: 'Reports',
        path: '/admin/reports',
        authTest: auth =>  (( isAgencyAdmin(auth)) && isAgencyApproved(auth))
    },
    {
        text: 'Company Settings',
        path: ROUTES.FRONTEND.COMPANY.EDIT_OWN,
        authTest: auth => !isInternal(auth) && isCompanyAdmin(auth)
    },
];

const secondaryItems = {
    'my-edge': [
        {
            text: 'Search venues',
            icon: 'fal fa-search',
            desc:
                'Search a database consisting of over 19,000 venues. Ensuring you find the perfect venue for your next event or meeting.',
            buttonLabel: 'Find venues on EDGE',
            path: '/advanced-search',
            authTest: everybody,
        },
        {
            text: 'Your saved searches',
            icon: 'fal fa-save',
            desc: 'Pick up from where you left off and review your saved searches.',
            buttonLabel: 'View saved searches',
            path: '/admin/my-edge/saved-searches',
            authTest: everybody,
        },
        {
            text: 'Your shortlists',
            icon: 'fal fa-heart',
            desc: 'Review venues you\'ve shortlisted.',
            buttonLabel: 'View shortlists',
            path: '/admin/my-edge/shortlists',
            authTest: everybody,
        },
        {
            text: 'Your notes',
            icon: 'fal fa-pencil-alt',
            desc: 'Read through your notes.',
            buttonLabel: 'View notes',
            path: '/admin/my-edge/notes',
            authTest: everybody,
        },
        {
            text: 'Your RFPs',
            icon: 'fal fa-file-invoice',
            desc:
                'Manage and view your Request for Proposals',
            path: '/admin/my-edge/your-rfps',
            authTest: auth => !isInternal(auth) && !hasVenues(auth)  && !isRolePlatformAdmin(auth) && !isAgencyUser(auth) && !isAgencyAdmin(auth) && !isAgencyUserType(auth),
            buttonLabel: 'View your proposals',
        },
        {
            text: 'Draft RFPs',
            icon: 'fal fa-file-invoice',
            desc: 'Manage and view your Drafted Enquiries',
            path: '/admin/my-edge/draft-rfps',
            authTest: auth => !isInternal(auth) && !hasVenues(auth)  && !isRolePlatformAdmin(auth) && !isAgencyUser(auth) && !isAgencyAdmin(auth) && !isAgencyUserType(auth),
            buttonLabel: 'View your draft proposals',
        },
    ],
    venues: [
        {
            text: 'Your venues',
            icon: 'fa fa-hotel',
            desc: 'View and manage your venues.',
            buttonLabel: 'View your venues',
            path: '/admin/venues/your-venues',
            authTest: auth => isInternal(auth) || (hasVenues(auth)) || isEdgeSupport(auth),
        },
        {
            text: 'Manage Licences',
            listContent: [
              'Access to 24/7 marketing uploads',
              'Enhanced search positions',
              'Business Intelligence Reports',
              'Invites to EDGE Events/Marketing Summits',
              'EDGE Insights and newsletters'
            ],
            icon: 'fa fa-file-invoice',
            buttonLabel: 'View your venue licences',
            path: VENUE_ROUTES.FRONTEND.MANAGE_VENUE_LICENCE.INDEX,
            authTest: auth => hasVenues(auth) && canEditVenue(auth),
        },
        {
            text: 'Venue importer',
            icon: 'far fa-file-import',
            desc: 'Bulk import your venue data into EDGE Venues.',
            buttonLabel: 'Import your venues',
            path: '/admin/venues/venue-importer',
            authTest: auth => isInternal(auth) || canCreateVenue(auth) || isEdgeSupport(auth),
        },
        {
            text: 'Meeting room importer',
            icon: 'far fa-file-import',
            desc: 'Bulk import your meeting room data into EDGE Venues.',
            buttonLabel: 'Import your meeting rooms',
            path: '/admin/venues/meeting-room-importer',
            authTest: auth => isInternal(auth) || isEdgeSupport(auth),
        },
        {
            text: 'Create a new venue',
            icon: 'fal fa-plus',
            desc: 'Add a single venue to EDGE Venues.',
            buttonLabel: 'Create new venue',
            path: '/admin/venues/create-a-new-venue',
            authTest: auth => isRolePlatformAdmin(auth) || canCreateVenue(auth) || isEdgeSupport(auth),
        },
        {
            text: 'Hotel Groups',
            icon: 'fal fa-cog',
            desc: 'View hotel groups',
            buttonLabel: 'View hotel groups',
            path: VENUE_ROUTES.FRONTEND.HOTEL_GROUPS.INDEX,
            authTest: auth => isRoleEdgeAdmin(auth) || isEdgeSupport(auth),
        },
        {
            text: 'Create Hotel Group',
            icon: 'fal fa-plus',
            desc: 'Create a hotel group',
            buttonLabel: 'Create hotel group',
            path: VENUE_ROUTES.FRONTEND.HOTEL_GROUPS.CREATE,
            authTest: auth => isRoleEdgeAdmin(auth) || isEdgeSupport(auth),
        },
    ],
    subscriptions: [
        {
            text: 'All Subscriptions',
            path: '/admin/subscriptions/all',
            desc: 'View all current EDGE subscriptions',
            buttonLabel: 'All Subscriptions',
            authTest: auth => canSeeSubscriptions(auth),
        },
        {
            text: 'Create Subscription',
            path: '/admin/subscriptions/create',
            desc: 'Create a new EDGE subscription',
            buttonLabel: 'Create new subscription',
            authTest: auth => canCreateSubscriptions(auth),
        },
        {
            text: 'Reports',
            path: '/admin/subscriptions/reports',
            desc: 'View reporting on all EDGE subscriptions',
            buttonLabel: 'View Reports',
            authTest: auth => canCreateSubscriptions(auth),
        },
    ],
    'site-visits': [
        {
            text: 'Submit Site Report',
            icon: 'fal fa-plus',
            path: '/admin/site-visits/create',
            desc: 'Submit your site visit report for a venue',
            buttonLabel: 'New Report',
            authTest: auth => isInternal(auth) || isIndividualUserType(auth) || isAgencyUser(auth) || isCompanyUser(auth),
        },
        {
            text: 'Site Visits',
            icon: 'far fa-eye',
            path: '/admin/site-visits/all',
            desc: 'View all site visit reports.',
            buttonLabel: 'View All',
            authTest: auth => isRolePlatformAdmin(auth) || isAgencyAdmin(auth) || isAgencyUser(auth),
        },
        {
            text: 'View My Site Visit Reports',
            path: '/admin/site-visits/my-reports',
            icon: 'far fa-eye',
            desc: 'View a list of your submitted site visits, both approved and unapproved',
            buttonLabel: 'My Reports',
            authTest: auth => isAgencyUser(auth) || isIndividualUserType(auth) || isCompanyUser(auth) || isInternal(auth),
        },
        {
            text: 'Awaiting Approval',
            icon: 'fas fa-clipboard-list',
            path: '/admin/site-visits/to-approve',
            desc:
                'View all submitted site visit reports that are still awaiting admin approval',
            buttonLabel: 'View Reports',
            authTest:  auth => isRolePlatformAdmin(auth) || isAgencyAdmin(auth) || isAgencyUser(auth),
        },
    ],
    agency: [
        {
            text: 'Manage agencies',
            icon: 'fal fa-user-tie',
            desc: 'Find and manage agencies',
            buttonLabel: 'Manage agencies',
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.MANAGE,
            authTest: auth => isAgencyAdmin(auth) || (canAdministerAgencies(auth) && !isEdgeFinance(auth)) || isRolePlatformAdmin(auth) || isEdgeSupport(auth)
        },
        {
            text: 'Create Agency',
            icon: 'fal fa-user-tie',
            desc: 'Create an agency',
            buttonLabel: 'Create agency',
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.CREATE,
            authTest: auth => isRoleEdgeAdmin(auth)  || isEdgeSupport(auth) || (auth && auth.agency && auth.agency.is_super_agent && auth.agency.can_add_child_agencies)
        },
        {
            text: 'Manage companies',
            icon: 'fal fa-building',
            desc: 'Find and manage companies',
            buttonLabel: 'Manage companies',
            path: CORPORATE_ROUTES.FRONTEND.COMPANY.ALL,
            authTest: auth => isRolePlatformAdmin(auth)  || isEdgeSupport(auth) || (isAgencyAdmin(auth) && isAgencyApproved(auth) ), //canAdministerCompany(auth) && !isCompanyAdmin(auth)
        },
        {
            text: 'Reports',
            icon: 'fal fa-user-tie',
            desc: 'View reports',
            buttonLabel: 'Manage agencies',
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.REPORTS.VIEW,
            authTest: auth => isRolePlatformAdmin(auth) || isEdgeFinance(auth)
        },
    ],
    users: [
        {
            text: 'Manage users',
            icon: 'fal fa-users',
            desc: 'Find and manage existing users',
            buttonLabel: 'Manage users',
            path: '/admin/users/manage',
            authTest: auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth) || canEditUsers(auth),
        },
        {
            text: 'Create a new user',
            icon: 'fal fa-user-plus',
            desc: 'Add a new user to EDGE Venues.',
            buttonLabel: 'Create new user',
            path: '/admin/users/create',
            authTest: auth =>isRolePlatformAdmin(auth) || isEdgeSupport(auth) || canEditUsers(auth),
        },
        {
            text: 'Activations',
            icon: 'fal fa-user-check',
            desc: 'Review which users have recently activated their account.',
            buttonLabel: 'View activations',
            path: '/admin/users/activations',
            authTest: auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth),
        },
        {
            text: 'Manage Roles',
            path: '/admin/users/roles',
            icon: 'fal fa-cog',
            desc: 'Manage Roles and Permissions used by the system',
            buttonLabel: 'Manage Roles',
            authTest: auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth),
        },
        {
            text: 'Create New Role',
            path: '/admin/roles/create',
            icon: 'fal fa-cog',
            desc: 'Adding a New role requires code changes from dev team',
            buttonLabel: 'Add a New Roles',
            authTest: auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth) || canEditRoles(auth),
        },
    ],
    rfp: [],
    'corporate-accounts': [
        {
            text: 'Manage Corporate Accounts',
            icon: 'fal fa-users',
            desc: 'Find and manage existing corporate accounts',
            buttonLabel: 'Corporate Accounts',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE.ALL,
            authTest: auth => isInternal(auth) ||  ((isAgencyUser(auth) || isAgencyAdmin(auth)) && canHaveCorporateAccounts(auth)),
        },
        {
            text: 'Manage Public Events',
            icon: 'fal fa-calendar-alt',
            desc: 'Manage Public Events',
            buttonLabel: 'Public Events',
            path: CORPORATE_ROUTES.FRONTEND.EVENTS.ALL,
            authTest: auth => isRoleEdgeAdmin(auth) ||  isEdgeFinance(auth)     ,
        },
        {
            text: 'Events',
            icon: 'fal fa-list',
            desc: 'View all event data, manage the bookings and export data',
            buttonLabel: 'View all events',
            path: CORPORATE_ROUTES.FRONTEND.BOOKED_EVENTS.ALL,
            authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyAdmin(auth)) && (hasActiveCorporateAccount(auth) && hasCorporateAccess(auth)),
        },
        {
            text: 'Documents',
            icon: 'fal fa-file-download',
            desc: 'View useful document and information',
            buttonLabel: 'View all documents',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE.DOCUMENTS.CATEGORY,
            type: 'corporate',
            authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth)) && (hasActiveCorporateAccount(auth) && hasCorporateAccess(auth) && canAccessCorporateDocuments(auth)),
        },
        {
            text: 'Event Calendar',
            icon: 'fal fa-calendar-alt',
            desc: 'View a calendar of company-wide events',
            buttonLabel: 'View calendar',
            path: CORPORATE_ROUTES.FRONTEND.EVENTS.COMPANY_ALL,
            type: 'corporate',
            authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth) || isNonEventSpecialist(true)) && (hasActiveCorporateAccount(auth) && hasCorporateAccess(auth)),
        },
        {
            text: 'Company Locations',
            icon: 'fal fa-map-marker-alt',
            desc: 'View key company locations and recommended venues',
            buttonLabel: 'View locations',
            path: CORPORATE_ROUTES.FRONTEND.LOCATIONS.COMPANY_ALL,
            type: 'corporate',
            authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth)) && (hasActiveCorporateAccount(auth) && hasCorporateAccess(auth)),
        },
        {
            text: 'Manage Industry Events',
            icon: 'fal fa-edit',
            desc: 'Add key industry events to the calendar',
            buttonLabel: 'Manage industry events',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE.INDUSTRY_EVENTS.MANAGE,
            type: 'corporate',
            authTest: auth => ((isCompanyAdmin(auth)) && hasCorporateAccount(auth) || canAccessCorporateCalendar(auth)),
        },
    ],

    edgeAnalytics: [],
    edgeCms: [],
    'content-management': [
        {
            text: "Blog",
            icon: "fal fa-megaphone",
            desc: "Update venue blog.",
            buttonLabel: "Manage blog articles",
            path: "/admin/content-management/articles/blog",
            authTest: everybody,
        },
        {
            text: "News",
            icon: "fal fa-newspaper",
            desc: "Manage your news articles.",
            buttonLabel: "Manage articles",
            path: "/admin/content-management/articles/news",
            authTest: everybody,
        },
        {
            text: "Renovation News",
            icon: "fal fa-paint-roller",
            desc: "Manage your renovation news articles.",
            buttonLabel: "Manage articles",
            path: "/admin/content-management/articles/renovation-news",
            authTest: everybody,
        },
        {
            text: "Movers and Shakers",
            icon: "fal fa-bolt",
            desc: "Manage your renovation news articles.",
            buttonLabel: "Manage articles",
            path: "/admin/content-management/articles/movers-and-shakers",
            authTest: everybody,
        },
        {
            text: "Special Offers",
            icon: "fal fa-tag",
            desc: "Speical offers.",
            buttonLabel: "Manage offers",
            path: "/admin/content-management/offers/special-offers",
            authTest: everybody,
        },
        {
            text: "Commission Offers",
            icon: "fal fa-percent",
            desc: "Commission Offers.",
            buttonLabel: "Manage offers",
            path: "/admin/content-management/offers/commission-offers",
            authTest: everybody,
        },
        {
            text: "Agency Rates",
            icon: "fal fa-percent",
            buttonLabel: "Manage agency rates",
            path: "/admin/content-management/agency-rates",
            authTest:  auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth) ,
        },
        {
            text: "Fam Trips",
            icon: "fal fa-suitcase-rolling",
            buttonLabel: "View Fam Trips",
            path: "/admin/content-management/fam-trips",
            authTest:  auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth),
        },
        {
            text: "Destination Profiles",
            icon: "fal fa-globe-americas",
            buttonLabel: "Manage destination profiles",
            path: "/admin/content-management/destinations",
            authTest:  auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth),
        },
        {
            text: "SEO",
            icon: "fal fa-search",
            desc: "",
            buttonLabel: "Manage SEO",
            path: "/admin/content-management/seo",
            authTest: auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth),
        },
        {
            text: 'Analytics Image',
            icon: 'fal fa-analytics',
            desc: '',
            buttonLabel: 'Manage Analytics Image',
            path: '/admin/content-management/analytics-image',
            authTest:  auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth),
        },
        {
            text: 'Venue Of The Week',
            icon: 'fas fa-building',
            desc: 'Set the Venue that appears on home page',
            buttonLabel: 'Manage Venue Of The Week',
            path: '/admin/content-management/venue-of-the-week',
            authTest:  auth => isRolePlatformAdmin(auth) || isEdgeSupport(auth),
        },
    ],
    agencyProfile: [
        {
            text: 'Agency Profile',
            desc: 'Agency Profile',
            buttonLabel: 'Agency Profile',
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.ALL,
            authTest: auth => isAgency
        }
    ],
    articles: [
        {
            text: 'Blog',
            icon: 'fal fa-megaphone',
            desc: 'Update venue blog.',
            buttonLabel: 'Manage blog articles',
            path: '/admin/content-management/articles/blog',
            authTest: everybody,
        },
        {
            text: 'News',
            icon: 'fal fa-newspaper',
            desc: 'Manage your news articles.',
            buttonLabel: 'Manage articles',
            path: '/admin/content-management/articles/news',
            authTest: everybody,
        },
        {
            text: 'Renovation News',
            icon: 'fal fa-paint-roller',
            desc: 'Manage your renovation news articles.',
            buttonLabel: 'Manage articles',
            path: '/admin/content-management/articles/renovation-news',
            authTest: everybody,
        },
        {
            text: 'Movers and Shakers',
            icon: 'fal fa-bolt',
            desc: 'Manage your renovation news articles.',
            buttonLabel: 'Manage articles',
            path: '/admin/content-management/articles/movers-and-shakers',
            authTest: everybody,
        },
    ],
    offers: [
        {
            text: 'Special Offers',
            icon: 'fal fa-tag',
            desc: 'Special offers.',
            buttonLabel: 'Manage offers',
            path: '/admin/content-management/offers/special-offers',
            authTest: everybody,
        },
        {
            text: 'Commission Offers',
            icon: 'fal fa-percent',
            desc: 'Commission Offers.',
            buttonLabel: 'Manage offers',
            path: '/admin/content-management/offers/commission-offers',
            authTest: everybody,
        },
    ],
    'reports': [
        {
            text: 'Cash flow report',
            icon: 'fal fa-chart-bar',
            desc: 'View cash flow report',
            buttonLabel: 'View cash flow',
            authTest: auth => isRoleEdgeAdmin(auth), // ((isAgencyUser(auth) || isAgencyAdmin(auth)) && isAgencyApproved(auth)),
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.REPORTS.CASH_FLOW,
        },
        {
            text: 'Spend Snapshot',
            icon: 'fal fa-chart-bar',
            desc: 'View spend snapshot',
            buttonLabel: 'View spend snapshot',
            authTest: auth => (( isAgencyAdmin(auth)) && isAgencyApproved(auth)),
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.REPORTS.SNAPSHOT.replace('agency/', ''),
        },
        {
            text: 'Spend Report',
            icon: 'fal fa-chart-pie',
            desc: 'View your companies spend report',
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.REPORTS.SPEND_REPORT.replace('agency/', ''),
            authTest: auth => (( isAgencyAdmin(auth)) && isAgencyApproved(auth)),
            buttonLabel: 'View your spend report',
        },
        {
            text: 'Annual Report',
            icon: 'fal fa-chart-pie',
            desc: 'View your companies annual report',
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.REPORTS.ANNUAL_REPORT.replace('agency/', ''),
            authTest: auth => isRoleEdgeAdmin(auth),// || ((isAgencyUser(auth) || isAgencyAdmin(auth)) && isAgencyApproved(auth)),
            buttonLabel: 'View your spend report',
        },
        {
            text: 'Enquiry Log',
            icon: 'fal fa-file-spreadsheet',
            desc: 'Filter and export your booked and cancelled events',
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.REPORTS.ENQUIRY_LOG.replace('agency/', ''),
            authTest: auth => (( isAgencyAdmin(auth)) && isAgencyApproved(auth)),
            buttonLabel: 'View your Enquiry Log',
        },

    ],
};

const homepageItems = [
    {
        text: 'Home',
        path: '/',
    },
    {
        text: 'About',
        path: '/about',
    },
    {
        text: 'Advanced search',
        path:
            '/advanced-search?results=13326&back_projection=0&exhibition=0&location_type=Map&radius=48280.32&sortBy=Capacity&types=1&types=2&types=3&types=4&types=5&types=6&types=7&types=8&types=9&types=10&types=11&types=12&types=13',
    },
    {
        text: 'UK Locations',
        path: '/uk-venue-locations',
    },
    {
        text: 'Worldwide locations',
        path: '/worldwide-venue-locations',
    },
    {
        text: 'Site visits',
        path: '/site-visits',

    },
    {
        text: 'Offers',
        path: '/special-offers',
        submenu: [
            {
                text: 'Commission Offers',
                path: '/commission-offers',
            },
            {
                text: 'Agency Rates',
                path: '/agency-rates',
            },
            {
                text: 'FAM Trips',
                path: '/fam-trips',
            },
        ],
    },
    {
        text: 'Venue news',
        path: '/edge-venue-news',
    },
    {
        text: 'Industry insights',
        path: '/the-word',
    },
    {
        text: 'Agency Info',
        path: '/',
        submenu: [
            {
                text: 'Movers and Shakers',
                path: '/movers-and-shakers',
            },
            {
                text: 'Renovation News',
                path: '/renovation-news',
            },
            {
                text: 'Venue news',
                path: '/edge-venue-news',
            },
            {
                text: 'Venue blog',
                path: '/edge-venue-blog',
            },
        ],
    },
];


const corporateItems = [
    {
        text: 'My EDGE',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.MY_EDGE.VIEW,
        authTest: everybody,
    },
    {
        text: 'Site Visits',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.SITE_VISITS.VIEW,
        authTest: everybody,
    },
    {
        text: 'Events',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.EVENTS.VIEW,
        authTest: auth => (isRoleEdgeAdmin(auth)) || ((isCompanyAdmin(auth) || isEventSpecialist(auth) || isHeadOfEvents(auth) || isHeadOfProcurement(auth) || isNonEventSpecialist(auth)) && hasCorporateAccount(auth)),
    },
    {
        text: 'Calendar',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.CALENDAR.VIEW,
        authTest: auth => (isCompanyUser(auth) && hasCorporateAccount(auth) && canAccessCorporateCalendar(auth)),
    },
    {
        text: 'Locations',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.LOCATIONS.VIEW,
        authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth)) && hasCorporateAccount(auth) && canAccessCorporateLocations(auth),
    },
    {
        text: 'Documents',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.DOCUMENTS.VIEW,
        authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth)) && hasCorporateAccount(auth) && canAccessCorporateDocuments(auth),
    },
    {
        text: 'Reports',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.REPORTS.VIEW,
        authTest: auth => (isCompanyUser(auth) || isCompanyAdmin(auth)) && (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasActiveCorporateAccount(auth),
        // authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isCompanyUser(auth) || isCompanyAdmin(auth)) && hasActiveCorporateAccount(auth),
    },
    {
        text: 'News / Offers',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.NEWS.VENUE_NEWS,
        authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth) || isEventSpecialist(auth) || isCompanyUser(auth)) && hasCorporateAccount(auth),
    },
    {
        text: 'Settings',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.SETTINGS.VIEW,
        authTest: auth => !isInternal(auth) && isCompanyAdmin(auth)
    },
    {
        text: 'Contact Us',
        path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.CMS.CORPORATE_CONTACT_US,
        authTest: auth => !isInternal(auth)
    },

    // {
    //   text: 'Site Visits',
    //   path: '/admin/site-visits',
    //   authTest: auth => isInternal(auth),
    // },

];

const corporateSecondaryItems = {
    // IGNORE THE DUPLICATION IN HERE - It controls the order of display for different user types.
    'dashboard': [
        {
            text: 'Company Events',
            icon: 'fal fa-list',
            desc: 'View all event data, manage the bookings and export data',
            buttonLabel: 'View all events',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.ALL,
            authTest: auth => (isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        },
        {
            text: 'My events',
            icon: 'fal fa-list',
            desc: 'View all event data, manage the bookings and export data',
            buttonLabel: 'View all events',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.ALL,
            authTest: auth => (isEventSpecialist(auth) || isNonEventSpecialist(auth)) && hasCorporateAccount(auth),
        },
        // {
        //     text: 'My Forthcoming Events',
        //     icon: 'fal fa-list',
        //     desc: 'View all future event data, manage the bookings and export data',
        //     buttonLabel: 'View all forthcoming events',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.ALL + `?eventDateFrom=` + format(new Date(), 'YYYY-MM-DD 00:00:00') + `&eventStatus=${BOOKED_EVENT_STATUS.CONFIRMED},${BOOKED_EVENT_STATUS.COMPLETED}`,
        //     authTest: auth => isEventSpecialist(auth) && hasCorporateAccount(auth),
        // },
        {
            text: 'Company Locations',
            icon: 'fal fa-map',
            desc: 'See your companies locations',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.LOCATIONS.VIEW,
            authTest: auth => (isEventSpecialist(auth) || isNonEventSpecialist(auth)) && hasCorporateAccount(auth) && canAccessCorporateLocations(auth),
            buttonLabel: 'View your Company Locations',
        },
        {
            text: 'Event bookings and support',
            icon: 'far fa-comments',
            desc: 'Click here to view our contact details',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.CMS.CORPORATE_CONTACT_US,
            authTest: auth => (isEventSpecialist(auth) || isNonEventSpecialist(auth)) && hasCorporateAccount(auth) && !canAccessCorporateLocations(auth),
            buttonLabel: 'Get in touch',
        },
        {
            text: 'Company Calendar',
            icon: 'fal fa-calendar',
            desc: 'See what\'s coming up',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.CALENDAR.VIEW,
            authTest: auth => (isEventSpecialist(auth) || isNonEventSpecialist(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth) && canAccessCorporateCalendar(auth),
            buttonLabel: 'View your Company Calendar',
        },
        {
            text: 'Ready to book?',
            icon: 'fal fa-calendar',
            desc: 'Enter your event details to get your quote',
            path: CORPORATE_ROUTES.FRONTEND.PROPOSALS.REQUEST,
            authTest: auth => (isEventSpecialist(auth) || isNonEventSpecialist(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth) && !canAccessCorporateCalendar(auth),
            buttonLabel: 'Send a new enquiry',
        },
        {
            text: 'Event Organisers Guide',
            icon: 'fal fa-file',
            desc: 'Our tips on organising an Event',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.CMS.EVENT_ORGANISERS_GUIDE,
            authTest: auth => (isEventSpecialist(auth) || isNonEventSpecialist(auth)) && hasCorporateAccount(auth),
            buttonLabel: 'View our Event Organisers Guide',
        },
        {
            text: 'Company Locations',
            icon: 'fal fa-map',
            desc: 'See your companies locations',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.LOCATIONS.VIEW,
            authTest: auth => (isHeadOfEvents(auth)) && hasCorporateAccount(auth),
            buttonLabel: 'View your Company Locations',
        },
        {
            text: 'Reports',
            icon: 'fal fa-chart-pie',
            desc: 'View your reports',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.REPORTS.VIEW,
            authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasActiveCorporateAccount(auth),
            buttonLabel: 'View your Reports',
        },
        {
            text: 'Documents',
            icon: 'fal fa-file',
            desc: 'View your companies documents',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.DOCUMENTS.VIEW,
            authTest: auth => (isHeadOfProcurement(auth)) && hasActiveCorporateAccount(auth),
            buttonLabel: 'View your Documents',
        },
        {
            text: 'Company events',
            icon: 'fal fa-list',
            desc: 'View all event data, manage the bookings and export data',
            buttonLabel: 'View all events',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.ALL,
            authTest: auth => (isHeadOfProcurement(auth)) && hasCorporateAccount(auth),
        },
        {
            text: 'KPI Document',
            icon: 'fas fa-chart-scatter',
            desc: 'Download the KPI document',
            buttonLabel: 'Download KPI Document',
            path: CORPORATE_ROUTES.API.CORPORATE.DOCUMENTS.KPI_GET,
            type: 'corporate',
            authTest: auth => (isHeadOfProcurement(auth)) && hasCorporateAccount(auth) && hasCorporateKpiDocument(auth) // THIS SHOULD SHOW IF THERE IS A KPI DOC FOR HoP
        },
        {
            text: 'Company Calendar',
            icon: 'fal fa-calendar',
            desc: 'See what\'s coming up',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.CALENDAR.VIEW,
            authTest: auth => (isHeadOfProcurement(auth)) && hasCorporateAccount(auth) && canAccessCorporateCalendar(auth) && !hasCorporateKpiDocument(auth),// THIS SHOULD SHOW IF THERE IS NO KPI DOC FOR HoP
            buttonLabel: 'View your Company Calendar',
        },
        // <--- items specific to head of procurement / head of events
        //   {
        //     text: 'Spend Report',
        //     icon: 'fal fa-chart-pie',
        //     desc: 'View your companies spend report',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.REPORTS.SPEND_REPORT,
        //     authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //     buttonLabel: 'View your spend report',
        //   },
        //   {
        //     text: 'Enquiry Log',
        //     icon: 'fal fa-file-spreadsheet',
        //     desc: 'Filter and export your booked and cancelled events',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.REPORTS.ENQUIRY_LOG,
        //     authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //     buttonLabel: 'View your Enquiry Log',
        //   },
        //   {
        //     text: 'View all events',
        //     icon: 'fal fa-list',
        //     desc: 'View all event data, manage the bookings and export data',
        //     buttonLabel: 'View all events',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.ALL,
        //     authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //   },
        //   {
        //     text: 'Company Documents',
        //     icon: 'fal fa-file',
        //     desc: 'View your companies documents',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.DOCUMENTS.VIEW,
        //     authTest: auth => (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //     buttonLabel: 'View your Company Documents',
        //   },
        //   // --->
        //   // <--- General corporate user items
        //   {
        //     text: 'Plan an Event',
        //     icon: 'fal fa-calendar-check',
        //     desc:
        //         'Create a brief, and select a venue by name or ask for our expert advice on suitable venues',
        //     buttonLabel: 'Create a new event brief',
        //     path: "/corporate/request-for-proposal",
        //     authTest: auth => !(isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //   },
        //   {
        //     text: 'Event News',
        //     icon: 'fal fa-newspaper',
        //     desc: 'News and Offers from our venues and the industry',
        //     buttonLabel: 'News and Offers',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.NEWS.VIEW,
        //     authTest: auth => !(isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //   },
        //   {
        //     text: 'Company Documents',
        //     icon: 'fal fa-file',
        //     desc: 'View your companies documents',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.DOCUMENTS.VIEW,
        //     authTest: auth => !(isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //     buttonLabel: 'View your Company Documents',
        //   },
        //   {
        //     text: 'Company Calendar',
        //     icon: 'fal fa-calendar',
        //     desc: 'See what\'s coming up',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.CALENDAR.VIEW,
        //     authTest: auth => !(isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //     buttonLabel: 'View your Company Calendar',
        //   },
        //   {
        //     text: 'Company Locations',
        //     icon: 'fal fa-map',
        //     desc: 'See your companies locations',
        //     path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.LOCATIONS.VIEW,
        //     authTest: auth => !(isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //     buttonLabel: 'View your Company Locations',
        //   },
        //   {
        //     text: 'Your RFPs',
        //     icon: 'fal fa-calendar-day',
        //     desc:
        //         'Manage and view your Request for Proposals',
        //     path: '/corporate/events/your-rfps',
        //     authTest: auth => !isInternal(auth) && !hasVenues(auth) && !(isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasCorporateAccount(auth),
        //     buttonLabel: 'View your proposals',
        //   },
    ],
    'my-edge': [
        {
            text: 'Your saved searches',
            icon: 'fal fa-save',
            desc: 'Pick up from where you left off and review your saved searches.',
            buttonLabel: 'View saved searches',
            path: '/corporate/my-edge/saved-searches',
            authTest: everybody,
        },
        {
            text: 'Your shortlists',
            icon: 'fal fa-heart',
            desc: 'Review venues you\'ve shortlisted.',
            buttonLabel: 'View shortlists',
            path: '/corporate/my-edge/shortlists',
            authTest: everybody,
        },
        {
            text: 'Your notes',
            icon: 'fal fa-pencil-alt',
            desc: 'Read through your notes.',
            buttonLabel: 'View notes',
            path: '/corporate/my-edge/notes',
            authTest: everybody,
        },
    ],
    'events': [
        {
            text: 'Plan an event',
            icon: 'fal fa-calendar-check',
            desc:
                'Create a brief, and select a venue by name or ask for our expert advice on suitable venues',
            buttonLabel: 'Create a new event',
            path: "/corporate/request-for-proposal",
            authTest: everybody,
        },
        {
            text: 'Search venues',
            icon: 'fal fa-search',
            desc:
                'Search a database consisting of over 19,000 venues. Ensuring you find the perfect venue for your next event or meeting.',
            buttonLabel: 'Search for venues',
            path: "/corporate/advanced-search?results=13326&back_projection=0&exhibition=0&location_type=Map&radius=24140.16&sortBy=Capacity&types_text=Hotels&types=1&types=2&types=3&types=4&types=5&types=6&types=7&types=8&types=9&types=10&types=11&types=12&types=13",
            authTest: everybody,
        },
        {
            text: 'Use Previous Templates',
            icon: 'fal fa-file-invoice',
            desc: 'Quickly create an enquiry based on a previous event template brief',
            path: '/corporate/templates',
            authTest: auth => !isInternal(auth) && !hasVenues(auth),
            buttonLabel: 'View your templates',
        },
        {
            text: 'Draft RFPs',
            icon: 'fal fa-edit',
            desc: 'Manage and view your Draft enquiries',
            path: '/corporate/events/draft-rfps',
            authTest: auth => !isInternal(auth) && !hasVenues(auth),
            buttonLabel: 'View your draft enquiries',
        },
        {
            text: 'Your RFPs',
            icon: 'fal fa-calendar-day',
            desc:
                'Manage and view your Request for Proposals',
            path: '/corporate/events/your-rfps',
            authTest: auth => !isInternal(auth) && !hasVenues(auth) && !isAgencyUserType(auth),
            buttonLabel: 'View your proposals',
        },
        {
            text: 'Events',
            icon: 'fal fa-list',
            desc: 'Manage and view your booked events',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.BOOKED_EVENTS.ALL,
            authTest: auth => !isInternal(auth) && !hasVenues(auth),
            buttonLabel: 'View your booked events',
        },

    ],
    'reports': [
        {
            text: 'Cash flow report',
            icon: 'fal fa-chart-bar',
            desc: 'View cash flow report',
            buttonLabel: 'View cash flow',
            authTest: auth => isRoleEdgeAdmin(auth), //(isCompanyUser(auth) || isCompanyAdmin(auth)) && (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasActiveCorporateAccount(auth),
            path: CORPORATE_ROUTES.FRONTEND.AGENCY.REPORTS.CASH_FLOW,
        },
        {
            text: 'Spend Snapshot',
            icon: 'fal fa-chart-bar',
            desc: 'View spend snapshot',
            buttonLabel: 'View spend snapshot',
            authTest: auth => (isCompanyUser(auth) || isCompanyAdmin(auth)) && (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasActiveCorporateAccount(auth),
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.REPORTS.SNAPSHOT,
        },
        {
            text: 'Spend Report',
            icon: 'fal fa-chart-pie',
            desc: 'View your companies spend report',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.REPORTS.SPEND_REPORT,
            authTest: auth => (isCompanyUser(auth) || isCompanyAdmin(auth)) && (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasActiveCorporateAccount(auth),
            buttonLabel: 'View your spend report',
        },
        {
            text: 'Annual Report',
            icon: 'fal fa-chart-pie',
            desc: 'View your companies annual report',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.REPORTS.ANNUAL_REPORT,
            authTest: auth => false, // (isCompanyUser(auth) || isCompanyAdmin(auth)) && (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasActiveCorporateAccount(auth),
            buttonLabel: 'View your annual report',
        },
        {
            text: 'Enquiry Log',
            icon: 'fal fa-file-spreadsheet',
            desc: 'Filter and export your booked and cancelled events',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.REPORTS.ENQUIRY_LOG,
            authTest: auth => (isCompanyUser(auth) || isCompanyAdmin(auth)) && (isHeadOfProcurement(auth) || isHeadOfEvents(auth)) && hasActiveCorporateAccount(auth),
            buttonLabel: 'View your Enquiry Log',
        },
    ],
    'news': [
        {
            text: 'Venue News',
            icon: 'fal fa-newspaper',
            desc: 'News and Offers from our venues and the industry',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.NEWS.VENUE_NEWS,
            authTest: everybody,
        },
        {
            text: 'Expert Insights',
            icon: 'fal fa-newspaper',
            desc: 'Insights',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.NEWS.INSIGHTS,
            authTest: everybody,
        },
        {
            text: 'EDGE Team Updates',
            icon: 'fal fa-alarm-exclamation',
            desc: 'Insights',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.NEWS.CORPORATE_TEAM,
            authTest: auth => canAccessEdgeTeamUpdates(auth),
        },
        {
            text: 'Special Offers',
            icon: 'fal fa-tag',
            desc: 'Manage and view your Request for Proposals',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.NEWS.SPECIAL_OFFERS,
            authTest: everybody,
        },
    ],
    'settings': [
        {
            text: 'Manage Company Users',
            icon: 'fal fa-users',
            desc: 'Manage your company users, invite new users and assign roles.',
            buttonLabel: 'Manage Users',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.COMPANY.EDIT,
            authTest: everybody,
        },
        {
            text: 'Manage Industry Events',
            icon: 'fal fa-edit',
            desc: 'Add key industry events to the calendar',
            buttonLabel: 'Manage industry events',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.INDUSTRY_EVENTS.MANAGE,
            type: 'corporate',
            authTest: auth => (isCompanyAdmin(auth)) && hasCorporateAccount(auth) && canAccessCorporateCalendar(auth),
        },
    ],
    'manage-industry-events': [
        {
            text: 'Edit Company Profile',
            icon: 'fal fa-building',
            desc: 'Manage your company profile.',
            buttonLabel: 'Company Profile',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.COMPANY.EDIT,
            authTest: everybody,
        },
        {
            text: 'Manage Industry Events',
            icon: 'fal fa-edit',
            desc: 'Add key industry events to the calendar',
            buttonLabel: 'Manage industry events',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.INDUSTRY_EVENTS.MANAGE,
            type: 'corporate',
            authTest: auth => (isCompanyAdmin(auth)) && hasCorporateAccount(auth) && canAccessCorporateCalendar(auth),
        },
    ],
    'site-visits': [
        {
            text: 'View Site Visits',
            icon: 'fal fa-eye',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.SITE_VISITS.ALL,
            desc: 'View Site Visits',
            buttonLabel: 'View',
            authTest: auth => isCompanyUser(auth),
        },
        {
            text: 'Submit Site Report',
            icon: 'fal fa-plus',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.SITE_VISITS.CREATE,
            desc: 'Submit your site visit report for a venue',
            buttonLabel: 'New Report',
            authTest: auth => isInternal(auth) || isAgency || isCompanyUser(auth),
        },
        {
            text: 'View My Site Visit Reports',
            path: CORPORATE_ROUTES.FRONTEND.CORPORATE_USER.SITE_VISITS.MY_REPORTS,
            icon: 'far fa-eye',
            desc: 'View a list of your submitted site visits, both approved and unapproved',
            buttonLabel: 'My Reports',
            authTest: auth => isInternal(auth) || isAgency || isCompanyUser(auth),
        }
    ]
}


const getMenuItems = (auth, menuItems) => menuItems.filter(({authTest}) => (authTest ? authTest(auth) : true));


const wpRedirects = {
    // '/': process.env.REACT_APP_WORDPRESS_URL,
    '/about': process.env.REACT_APP_WORDPRESS_URL + '/about/',
    '/uk-venue-locations': process.env.REACT_APP_WORDPRESS_URL + '/uk-venues/',
    '/worldwide-venue-locations': process.env.REACT_APP_WORDPRESS_URL + '/worldwide-venues/',
    '/the-word': process.env.REACT_APP_WORDPRESS_URL + '/the-word/',
    '/privacy': process.env.REACT_APP_WORDPRESS_URL + '/privacy-policy/',
    '/cookies': process.env.REACT_APP_WORDPRESS_URL + '/cookies/',
    '/contact': process.env.REACT_APP_WORDPRESS_URL + '/contact/',
    '/special-offers': process.env.REACT_APP_WORDPRESS_URL + '/venue-offers/',
    '/help': process.env.REACT_APP_WORDPRESS_URL + '/knowledge-hub/',
    '/support-desk': 'https://edgesupportdesk.atlassian.net/servicedesk/customer/portals'
}

export {getMenuItems, secondaryItems, homepageItems, corporateItems, corporateSecondaryItems, wpRedirects};

export default items;
