import React, { useEffect, useState } from "react";
import Modal from "../Modal";
import { Link } from "react-router-dom";
import { ButtonBrand, ButtonSpan } from "../Button";
import { LICENCE_TYPE_CODES } from "../../constants";
import StripeForm from "../Stripe/StripeForm";
import { currencyFormatter } from "../../utils";
import { LabelledText } from "../../ui";

const Col = ({ title, text, className = "md:w-1/2 lg:w-1/4 pr-2" }) => (
  <div className={className}>
    <LabelledText label={title} text={text} styles={{}}/>
  </div>
);

function getModalText(licenceType) {
  switch (licenceType.code) {
    case LICENCE_TYPE_CODES.OPT_OUT_LICENCE_CODE:
      return <>By Opting out I agree to EDGE Venues <Link to={"/platform-fee-terms-and-conditions"} target={"_blank"} className={"mx-1"}>Terms and Conditions</Link> and note that whilst I will
        pay no platform fee my venue will still get basic venue promotion, a simple andeffective RFP tool, 4 black and white images and exposure to event planners including corporate and agency
        buyers</>;
    default:
      return <>I agree to EDGE Venues <Link to={"/platform-fee-terms-and-conditions"} target={"_blank"} className={"mx-1"}>Terms and Conditions</Link> and note that EDGE platform fees of
        of {licenceType.agency_platform_fee}%, if booked via an Agent (and subject to the Agents&rsquo; normal commission fees) and {licenceType.direct_platform_fee}% if via a direct booking. These
        shall apply to all confirmed event business introduced by EDGE Venues from 10th of February 2025.</>;
  }
}

export const LicenceAgreementModal = ({ onProceed, onClose, licenceType, venueData }) => {
  const choiceTitle = licenceType.title;
  const optInText = getModalText(licenceType);
  const [agreement, setAgreement] = useState(false);
  const [priceData, setPriceData] = useState();
  const [productData, setProductData] = useState();
  const [discountedPrice, setDiscountedPrice] = useState(null);
  const [discountDescription, setDiscountDescription] = useState();

  useEffect(() => {
    if (licenceType.requires_payment && !!licenceType?.product?.price?.price_id) {
      setProductData(licenceType.product);
      setPriceData(licenceType.product.price);
    }
  }, []);

  return (<Modal>
    <div className="modal">
      <div
        className="modal-inner font-bold"
        style={{
          marginTop: "15%", overflow: "auto",
        }}
      >
        <ButtonSpan
          className="m-3 absolute pin-t pin-r z-10"
          onClick={onClose}
        >
          <i className="fas fa-times-circle fa-2x ml-3"/>
        </ButtonSpan>
        <div>
          <h2 className="mb-3 text-3xl">Please confirm your choice: {choiceTitle} </h2>
          {licenceType.requires_payment && !!licenceType?.product?.price?.price_id ? (

            <StripeForm
              licenceType={licenceType}
              venue={venueData}
              product={productData}
              price={priceData}
              onSuccess={onProceed}
              discountedPrice={discountedPrice}
              discountDescription={discountDescription}
              setDiscountedPrice={setDiscountedPrice}
              setDiscountDescription={setDiscountDescription}
              priceComponent={<>
                <div className="md:flex flex px-5 pt-6 -mb-6">
                  {priceData ? (
                    <Col
                      title="Monthly Amount"
                      text={typeof discountedPrice !== "undefined" && discountedPrice !== null ? currencyFormatter(priceData?.currency).format(discountedPrice) : `${currencyFormatter(priceData?.currency).format(priceData?.amount / 100)} +VAT`}
                    />
                  ) : null}
                  {discountDescription ? (
                    <Col
                      title="Discount Applied"
                      text={typeof discountDescription !== "undefined" && discountDescription !== null ? discountDescription : null}
                    />
                  ) : null}
                  <Col title="Venue" text={venueData.name}/>
                  <Col title="Licence Start" text={new Date().toDateString()}/>
                </div>
              </>}
            />
          ) : (
            <>
              <label className={"w-full mt-1 cursor-pointer"}>
                <input
                  className={"mr-5 mt-2"}
                  checked={agreement} type="checkbox"
                  onChange={(e) => {
                    setAgreement(!agreement);
                  }}/>
                {optInText}
              </label>
              <ButtonBrand
                disabled={!agreement}
                onClick={() => onProceed()}
                classes={"mt-4"}>Proceed
              </ButtonBrand>
            </>
          )}
        </div>
      </div>
    </div>
  </Modal>);
};