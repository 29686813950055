import React, {useState, useEffect} from "react";
import {
    FaUsers,
    FaHandshake,
    FaBed,
    FaPlaneDeparture,
    FaAngleDown,
    FaAngleUp,
} from "react-icons/fa";
import RoundedCheckbox from "Components/RoundedCheckbox";
import {ButtonBrand} from "Components/Button";
import {Link, Redirect} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedIn, isAgency, user, isCorporate, isInternal, isAgencyApproved, userType} from "modules/auth";
import {venueInteraction, venueImpression} from "modules/analytics";
import {pushEvent} from "modules/google-tag-manager";
import {withRouter} from "react-router-dom";
import RequestForQuote from "Components/RequestForQuote";
import Loading from "Components/Loading";
import Label from "./Label";
import ImagesCarousel from "./ImagesCarousel";
import {getWidth} from "redux-window";
import BackgroundImage from "../General/BackgroundImage";
import {
    FaShareAlt,
} from "react-icons/fa";
import {connect} from "react-redux";
import {CompareButton} from "./CompareButton";
import {VENUE_TAGS} from "../../modules/analytics/tags";
import PricingBandsSingleResult from "../../pages/Venue/PricingBandsSingleResult";
import ExclusionDatesBanner from "../PriceBanding/ExclusionDatesBanner";
import PriceBandingInformation
    from "../../pages/admin/EditVenue/ConferencePricing/PriceBanding/PriceBandingInformation";
import ReactTooltip from "react-tooltip";
import {FaPlus} from "react-icons/fa";
import { venueHasPermission } from "../../modules/permissions";
import { PERMISSIONS } from "../../constants";

const createShareHref = ({venue, user, brand}) => {
    return `mailto:?subject=${venue.name}&body=${shareEmailBody({
        venue,
        user,
        brand,
    })}`;
};

const shareEmailBody = ({venue, user, brand}) => {


    const url = `${window.BASE_URL}/venues/${venue.slug}`;

    const sharedBy =
        user && user.first_name ? ` by ${user.first_name} ${user.surname}` : "";

    const content = `The venue ${venue.name} has been shared with you${sharedBy}.

  ${url}

${"Edge Venues"}`;

    return encodeURIComponent(content);
};


const ResultImage = ({venue, image, user, brand, onClickThrough, greyscale = false}) => (
    <>
        <BackgroundImage
            className={`col col-w-full lg:col-w-1/4 bg-cover bg-center bg-no-repeat  relative overflow-hidden  `}
            imagePath={image}
            edits={greyscale ? {
                "resize": {
                    "width": 250,
                    "height": 250,
                    "fit": "cover",
                },
                "grayscale": true,
            } : {
                "resize": {
                    "width": 250,
                    "height": 250,
                    "fit": "cover",
                },
            }}
            placeholderImagePath={"/img/venue-placeholder.jpg"}
        >

            <div
                className="w-full h-full hidden lg:block absolute pin-t pin-b pin-l hover:bg-black-transparent transition image-with-details">
                <a
                    href={createShareHref({venue, user, brand})}
                    id={"share-venue-button"}
                    style={{transition: "opacity 0.2s ease-in-out", fontSize: 30, zIndex: 99}}
                    className={"share-venue-button"}
                    title={"Click to share this venue by email"}
                >
                    <FaShareAlt className="text-brand"/>
                </a>
                <Link to={`venue/${venue.slug}`} onClick={() => {
                    onClickThrough();
                }}>


                    <ButtonBrand
                        id="view-details-button"
                        small
                        style={{
                            transition: "opacity 0.2s ease-in-out",
                            padding: '10px'

                        }}
                        classes="absolute-center"
                    >
                        View details
                    </ButtonBrand>
                </Link>

                {/*{venue.covid_is_open ? <span id={'ribbon'} className={'ribbon'}  style={{*/}
                {/*  transition: 'opacity 0.2s ease-in-out',*/}
                {/*}}>Open</span> : null}*/}
            </div>
            <Link to={`/venue/${venue.slug}`} onClick={() => {
                onClickThrough();
            }}>
                <h2
                    className="block lg:hidden text-white py-2 px-4 mb-4"
                    style={{backgroundColor: "rgba(0,0,0,.6)"}}
                >
                    {venue.name}
                </h2>
            </Link>
        </BackgroundImage>
    </>
);

const ResultImageMobile = ({venue, image, user, brand, onClickThrough, greyscale}) => (
    <>

        <Link to={`venue/${venue.slug}`} onClick={() => {
            onClickThrough();
        }} className="w-full flex">

            <BackgroundImage
                className={`col col-w-full lg:col-w-1/4 bg-cover bg-center bg-no-repeat re-4/3 relative overflow-hidden ${greyscale ? "greyscale" : ""}`}
                imagePath={image}
                edits={greyscale ? {
                    "resize": {
                        "width": 672,
                        "height": 563,
                        "fit": "cover",
                    }, "grayscale": true,
                } : {
                    "resize": {
                        "width": 672,
                        "height": 563,
                        "fit": "cover",
                    },
                }}>

                {/*{venue.covid_is_open ? <span className={'ribbon'}>Open</span> : null}*/}
                <div className="block lg:hidden text-white py-2 px-4 mb-4 share-venue-button-mobile"
                     style={{backgroundColor: "rgba(0,0,0,.6)"}}>
                    <h2>{venue.name}</h2>
                    <a
                        href={createShareHref({venue, user, brand})}
                        id={"share-venue-button"}
                        className={"block lg:hidden"}
                        style={{fontSize: 30, zIndex: 99}}
                        title={"Click to share this venue by email"}
                    >
                        <FaShareAlt className="text-brand"/>
                    </a>
                </div>
            </BackgroundImage>
        </Link>
    </>
);

const SingleResult = ({
                          venue,
                          onSelect,
                          selected,
                          history,
                          onEnquiry,
                          onClickThrough,
                          user,
                          brand,
                          selectedVenues,
                          venueInteraction,
                          isAgencyApproved,
                          auctionData,
                          showSiteVisitsBadge,
                          priceBandings,
                      }) => {
    const [showDetails, setShowDetails] = useState(false);
    const [details, setDetails] = useState();
    const [images, setImages] = useState();
    const [featuredImage, setFeaturedImage] = useState(venue.cdn_image_path);
    const [showModal, setShowModal] = useState(false);
    const mobile = useSelector(state => getWidth(state) < 992);
    const isUserAgency = useSelector(state => isAgency(state));
    const isUserCorporate = useSelector(state => isCorporate(state));
    const isUserInternal = useSelector(state => isInternal(state));

    const splitAirportTime = !!venue.travel_time_from_nearest_airport
        ? venue.travel_time_from_nearest_airport.split(" ")
        : [];

    const loggedIn = useSelector(state => isLoggedIn(state));

    const onLoadDetails = async () => {
        setShowDetails(!showDetails);
        venueInteraction({
            type: VENUE_TAGS.QUICK_VIEW_CLICKED,
            id: venue.id,
            data_score: venue.data_score,
        });
        if (!details) {
            fetch(`${window.API}/venues/${venue.slug}/quick`)
                .then(response => response.json())
                .then(data => {
                    setDetails(data["venue"]);
                    setImages(data["images"]);
                });
        }
    };

    const instantBook = () => {
        if (!loggedIn) {
            history.push(
                `/login?return=${encodeURI(window.location.pathname)}&logInToProceed=1`,
            );
            return;
        }
        venueInteraction({
            type: VENUE_TAGS.WEB_ENQUIRIES.BUY_IT_NOW,
            id: venue.id,
            impression_id: venueImpression({
                id: venue.id,
                data_score: details.data_score,
            }),
            data_score: details.data_score,
        });
        pushEvent("enquire_venue_buynowclick", {
            ecommerce: {
                add: {
                    products: [
                        {
                            id: venue.id,
                            name: venue.name,
                            location: venue.location,
                        },
                    ],
                },
            },
        });

        setShowModal(true);
    };

    if (!venue.cdn_image_path) {
        fetch(`${window.API}/venues/images/${venue.slug}/path`)
            .then(response => response.json())
            .then(data => setFeaturedImage(data))
            .catch(err => {

            });
    }

    // let freeTier = venue.is_opted_out; // (venue.temp_permission === "free" && !isUserAgency && !isUserCorporate && !isUserInternal);
    return (
        <div className="my-4 bg-white">
            <div className="flex mb-4 bg-white">
                <div className={`w-full flex flex-wrap`}>
                    {mobile ? (
                        <ResultImageMobile
                            venue={venue}
                            image={featuredImage}
                            user={user}
                            brand={brand}
                            onClickThrough={onClickThrough}
                            greyscale={venue.is_opted_out}
                        />
                    ) : (
                        <ResultImage
                            venue={venue}
                            user={user}
                            image={featuredImage}
                            brand={brand}
                            onClickThrough={onClickThrough}
                            greyscale={venue.is_opted_out}
                        />
                    )}
                    <div className="col col-w-full lg:col-w-3/4 py-6">
                        <div className="row">
                            <div className="col col-w-full lg:col-w-7/10  flex flex-col justify-between">
                                <div>
                                    <Link
                                        to={`/venue/${venue.slug}`}
                                        className="text-black hover:underline hidden lg:inline"
                                        onClick={() => {
                                            onClickThrough();
                                        }}
                                    >
                                        <h2 className="text-xxl mb-4 inline">{venue.name}</h2>
                                    </Link>

                                    {/* If CSR https://edgevenues.atlassian.net/browse/EV-142 */}
                                    {((venue.csr_link && venue.csr_link.length > 0) || (venue.csr_text && venue.csr_text.length > 0)) &&
                                        <a
                                            title="CSR policy available - find out more"
                                            href={venue.csr_link}
                                            className="mr-2 lg:ml-2 lg:mr-0"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => {
                                                venueInteraction({
                                                    type: VENUE_TAGS.VIEW_CSR_LINK,
                                                    id: venue.id,
                                                    data_score: venue.data_score,
                                                });
                                            }}
                                        >
                                            <img src="/img/csr-policy.svg" alt="Venue has a CSR policy" width="20px" height="20px"/>
                                        </a>}
                                </div>
                                <div className="flex flex-wrap mb-4 mt-2 lg:mt-4">
                                    <div>
                                        {venue.ddr_price_banding_rate ?
                                            <div
                                                title={"Click for pricing details"}
                                                className={`rounded-sm bg-dark-green py-1 px-2 uppercase text-white text-sm font-normal mr-2`}>
                      <span
                          data-tip
                          data-for={"ddr"}
                          data-event="click"
                          className="flex items-center pointer"
                      >
                        DDR: {venue.band_title} <FaPlus className="ml-2"/>
                      </span>
                                                <ReactTooltip
                                                    id={"ddr"}
                                                    effect="solid"
                                                    type="light"
                                                    clickable={true}
                                                    className="tooltip-shadow"
                                                    globalEventOff="click"
                                                >
                                                    {!priceBandings && <Loading/>}
                                                    <div className="flex text-transform-none" style={{zIndex: "999999"}}>
                                                        <PriceBandingInformation priceBandings={priceBandings ? priceBandings["DDR"] : []}
                                                                                 isInternational={venue.is_international}/>
                                                    </div>
                                                </ReactTooltip>
                                            </div>

                                            : null}

                                    </div>

                                    {parseFloat(venue.buy_it_now) > 0 && <Label type="booking" fadeout={venue.is_opted_out}/>}

                                    {auctionData && auctionData.filter((auction) => parseInt(auction.venueId) === parseInt(venue.id)).length > 0 ?
                                        <Label type="auction" fadeout={true}/>
                                        :
                                        null
                                    }
                                    {showSiteVisitsBadge && (
                                        <Label type="siteVisits" venue={venue} onClickThrough={() => (
                                            <Redirect to="/login"/>
                                        )}/>
                                    )}
                                    {(isUserAgency && isAgencyApproved) &&
                                        venue.agencyCount + venue.commissionCount > 0 && (
                                            <Label
                                                type="offers"
                                                offers={venue.agencyCount + venue.commissionCount}
                                            />
                                        )}
                                    {venue.specialOffer && (
                                        <Label type="specialOffers" offers={venue.specialCount} venue={venue}
                                               onClickThrough={onClickThrough}/>
                                    )}
                                    {venue.destination_city && (
                                        <Label type="info" town={venue.destination_city}/>
                                    )}
                                    {/*#Hidden Site Visits*/}


                                </div>
                                {venue.is_opted_out ?
                                    null
                                    :
                                    <div className="row">
                                        <div className="col col-w-1/3 lg:col-w-1/4 flex flex-col justify-end">
                                            <p className="uppercase text-grey-md font-bold font-small">
                                                Max delegates
                                            </p>
                                            <div className="flex text-xl lg:text-sm xxl:text-xl font-bold items-center">
                                                <div className="hidden lg:flex items-center">
                                                    <FaUsers className="mr-2 text-xl"/>
                                                </div>
                                                <div>
                                                    <span className="font-heading">{venue.capacity}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-w-1/3 lg:col-w-1/4 flex flex-col justify-end">
                                            <p className="uppercase text-grey-md font-bold font-small">
                                                Meeting rooms
                                            </p>
                                            <div className="flex text-xl lg:text-sm xxl:text-xl font-bold items-center">
                                                <div className="hidden lg:flex items-center">
                                                    <FaHandshake className="mr-2 text-xl"/>
                                                </div>
                                                <div>
                        <span className="font-heading">
                          {venue.meeting_rooms}
                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col col-w-1/3 lg:col-w-1/4 flex flex-col justify-end">
                                            <p className="uppercase text-grey-md font-bold font-small">
                                                Bedrooms
                                            </p>
                                            <div className="flex text-xl lg:text-sm xxl:text-xl font-bold items-center">
                                                <div className="hidden lg:flex items-center">
                                                    <FaBed className="mr-2 text-xl"/>
                                                </div>
                                                <div>
                                                    <span className="font-heading">{venue.bedrooms}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {splitAirportTime.length > 0 && (
                                            <div className="col col-w-1/2 lg:col-w-1/4 hidden lg:flex flex-col justify-end">
                                                <p className="uppercase text-grey-md font-bold font-small">
                                                    Nearest airport
                                                </p>
                                                <div className="flex text-xl lg:text-sm xxl:text-xl font-bold items-center">
                                                    <div className="flex items-center">
                                                        <FaPlaneDeparture className="mr-2 text-xl"/>
                                                    </div>
                                                    <div>
                          <span className="font-heading">
                            {splitAirportTime[0]}
                          </span>
                                                        <span className="font-normal">
                            {splitAirportTime[1] === "hour"
                                ? "h"
                                : splitAirportTime[1]}
                          </span>
                                                        {splitAirportTime[2] && (
                                                            <>
                                                                {" "}
                                                                <span className="font-heading">
                                {splitAirportTime[2]}
                              </span>
                                                            </>
                                                        )}
                                                        {splitAirportTime[3] && (
                                                            <span className="font-normal">
                              {splitAirportTime[3]}
                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>}

                            </div>
                            <div className="flex flex-wrap w-full lg:hidden mt-4 pt-4 border-t border-grey">

                                {/*{venueHasPermission(venue, PERMISSIONS.CAN_DISPLAY_QUICK_VIEW) && USE BELOW UNTIL WE HAVE ACCESS TO PERMS IN SEARCH RESULTS */}
                                  {!venue?.is_opted_out &&
                                    <div
                                        className="col col-w-1/2 items-center uppercase pointer font-heading font-bold text-sm flex"
                                        onClick={onLoadDetails}
                                    >
                  <span className="text-sm">
                    {showDetails ? "Close" : "Quick view"}
                  </span>
                                        {showDetails ? (
                                            <FaAngleUp className="ml-4"/>
                                        ) : (
                                            <FaAngleDown className="ml-4"/>
                                        )}
                                    </div>
                                }
                                <div
                                    className={`col col-w-1/${!venue?.is_opted_out ? "2" : "1"} flex justify-end items-center pointer`}
                                    onClick={onSelect}
                                >
                  <span
                      className={`font-heading text-sm uppercase ${
                          selected ? "text-black" : "text-grey-darker"
                      } font-bold mr-3 xl:mr-2 xxl:mr-3`}
                  >
                    {selected ? "Selected" : "Choose venue"}
                  </span>
                                    <RoundedCheckbox size={31} checked={selected}/>
                                </div>
                            </div>
                            <div
                                className="col col-w-full lg:col-w-3/10 mt-8 lg:mt-0 hidden lg:flex lg:flex-col justify-between items-center lg:items-end">
                                <div className="w-full flex flex-wrap flex-row h-full">
                                    <div
                                        className="col col-w-full md:col-w-1/3 lg:col-w-full flex justify-center md:justify-start lg:justify-end items-center pointer"
                                        onClick={onSelect}
                                    >
                    <span
                        className={`font-heading text-sm uppercase ${
                            selected ? "text-black" : "text-grey-darker"
                        } font-bold mr-3 xl:mr-2 xxl:mr-3`}
                    >
                      {selected ? "Selected" : "Choose venue"}
                    </span>
                                        <RoundedCheckbox size={31} checked={selected}/>
                                    </div>
                                    <div
                                        className="col col-w-full mt-4 md:mt-0 md:col-w-1/3 lg:col-w-full lg:mt-4 flex justify-center md:justify-end">
                                        {(
                                            venue.temp_permission === "free"
                                            && !isUserAgency
                                            && !isUserCorporate
                                            && !isUserInternal
                                        ) ?
                                            <Link to={`/venue/${venue.slug}`} onClick={() => {
                                                onClickThrough();
                                            }}>
                                                <ButtonBrand
                                                    classes={`px-6 xl:px-4 xxl:px-6 text-base xl:text-sm xxl:text-base w-full md:w-auto grey-out single-result-button`}
                                                >
                                                    Find Out More
                                                </ButtonBrand>
                                            </Link>
                                            : [
                                                (selectedVenues.length > 1 && selected) ?
                                                    <CompareButton
                                                        key={venue.id}
                                                        venues={selectedVenues}
                                                        buttonText="Send Enquiry"
                                                        classes="px-6 xl:px-4 xxl:px-6 text-base xl:text-sm xxl:text-base w-full md:w-auto"
                                                    />
                                                    :
                                                    <Link key={venue.id} to={`/request-for-proposal?venues=${venue.id}`} onClick={() => {
                                                        onClickThrough();
                                                    }}>
                                                        <ButtonBrand
                                                            classes="px-6 xl:px-4 xxl:px-6 text-base xl:text-sm xxl:text-base w-full md:w-auto"
                                                        >
                                                            Send Enquiry
                                                        </ButtonBrand>
                                                    </Link>,
                                            ]
                                        }
                                    </div>
                                  {/*{venueHasPermission(venue, PERMISSIONS.CAN_DISPLAY_QUICK_VIEW) && USE BELOW UNTIL WE HAVE ACCESS TO PERMS IN SEARCH RESULTS */}
                                      {!venue?.is_opted_out &&
                                        <div
                                            className="col col-w-full mt-4 md:mt-0 md:col-w-1/3 lg:col-w-full items-center lg:col-w-full lg:mt-4 uppercase pointer font-heading font-bold text-sm flex justify-center md:justify-end"
                                            onClick={onLoadDetails}
                                        >
                    <span className="text-sm">
                      {showDetails ? "Close" : "Quick view"}
                    </span>
                                            {showDetails ? (
                                                <FaAngleUp className="ml-4"/>
                                            ) : (
                                                <FaAngleDown className="ml-4"/>
                                            )}
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showDetails && !details && (
                <div>
                    <Loading/>
                </div>
            )}
            {showDetails && details && (
                <div className="flex flex-wrap">
                    <div className="col hidden lg:block lg:col-w-1/2">
                        {showDetails && images && (
                            <div onClick={() => {
                                venueInteraction({
                                    type: VENUE_TAGS.QUICK_VIEW_GALLERY_IMAGE,
                                    id: venue.id,
                                    data_score: venue.data_score,
                                });
                            }}>
                                <ImagesCarousel images={images}/>
                            </div>
                        )}

                    </div>
                    <div className="col col-w-full lg:col-w-1/2 pb-4">
                        {/**
                         * EP-141 hide instant book and replace with Send enquiry for now
                         */}
                        <div className="flex flex-wrap w-full bg-light-grey py-4 px-3 flex justify-between items-center">
                            <Link to={`/request-for-proposal?venues=${venue.id}`} onClick={() => {
                                onClickThrough();
                            }}>
                                <ButtonBrand classes="text-lg mt-0 mt-4 xxl:mt-0 md:px-4 w-full md:w-auto">
                                    Send Enquiry
                                </ButtonBrand>
                            </Link>
                        </div>
                        {/*{parseFloat(details.buy_it_now_price_exc_vat) > 0 && (*/}
                        {/*  <div className="flex flex-wrap w-full bg-light-grey py-4 px-3 flex justify-between items-center">*/}
                        {/*    <span className="font-bold">Room hire up to 10 people</span>*/}
                        {/*    <span*/}
                        {/*      className="font-heading text-edge-blue text-xl md:text-base lg:text-xl"*/}
                        {/*      dangerouslySetInnerHTML={{*/}
                        {/*        __html: `${details.currency.html_code}${details.buy_it_now_price_exc_vat}`,*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*    <ButtonBrand*/}
                        {/*      classes="text-lg mt-0 mt-4 xxl:mt-0 md:px-4 w-full md:w-auto"*/}
                        {/*      onClick={instantBook}*/}
                        {/*    >*/}
                        {/*      Instant booking*/}
                        {/*    </ButtonBrand>*/}
                        {/*  </div>*/}
                        {/*)}*/}
                        <p className="my-4 font-normal pr-3">
                            {details.location && details.location.length > 200
                                ? `${details.location.substr(0, 200).trim()}...`
                                : details.location}
                        </p>
                        {details.packages.length > 0 &&
                            details.packages.filter(
                                singlePackage =>
                                    parseFloat(singlePackage.price_per_delegate_ex_vat).toFixed(
                                        0,
                                    ) === 0,
                            ).length !== details.packages.length && (
                                <>
                                    <h2 className="hidden lg:block">Conferences and Events</h2>
                                    <div className="mb-5">
                                        <PricingBandsSingleResult venue={venue} details={details} PB={priceBandings}/>
                                    </div>
                                    <p className="font-small text-dark-grey uppercase my-4 flex justify-between">
                                        Guideline prices exc vat <div className={"inline-block"}><ExclusionDatesBanner venue={venue.slug}
                                                                                                                       title={"excluding specific dates"}/>
                                    </div>
                                    </p>
                                </>
                            )}
                        <Link to={`venue/${venue.slug}`} onClick={() => {
                            onClickThrough();
                        }}>
                            <ButtonBrand
                                outlined
                                classes="md:text-lg border-4 rounded-sm w-full lg:w-auto"
                            >
                                View full details
                            </ButtonBrand>
                        </Link>
                        {mobile ? <div>
                            <br/>
                            <Link to={`/request-for-proposal?venues=${venue.id}`} onClick={() => {
                                onClickThrough();
                            }}>
                                <ButtonBrand

                                    classes="md:text-lg border-4 rounded-sm w-full lg:w-auto"
                                >
                                    Send Enquiry
                                </ButtonBrand>
                            </Link>
                        </div> : null}

                    </div>
                </div>
            )}
            {showModal && details && (
                <RequestForQuote
                    closeModal={() => setShowModal(!showModal)}
                    impression_id={venueImpression({
                        id: venue.id,
                        data_score: details.data_score,
                    })}
                    data_score={details.data_score}
                    initialValues={{
                        type: "Buy it now",
                        venues: [venue.id],
                        venues_text: [venue.name],
                    }}
                />
            )}
        </div>
    );
};

export default withRouter(
    connect(
        (state, props) => {
            // const userVenues = venues(state);
            let showSiteVisitsBadge = "number_of_site_visits" in props.venue && props.venue.number_of_site_visits > 0;
            if (userType(state) === "Venue") {// && userVenues.length > 0) {
                showSiteVisitsBadge = false; // userVenues.some(venue => venue.id === props.venue.id) && hasSiteVisits;
            }
            return {
                user: user(state),
                brand: state.brand,
                showSiteVisitsBadge,
                isAgencyApproved: isAgencyApproved(state),
                isLoggedIn: isLoggedIn(state),
            };
        },
        {venueInteraction},
    )(SingleResult));
