import React, { useCallback, useEffect, useMemo, useState } from "react";
import MultiSelect from "../../../ui/MultiSelect";
import { MultiValue, Option, ValueContainer } from "./MultiValueComponents";
import { ButtonGrey, ButtonPrimary } from "../../Button";
import Loading from "../../Loading";

const AgencyReportFilters = ({
                               agencies,
                               companies,
                               isRoleEdgeAdmin,
                               fetchCompanies,
                               token,
                               runReport = () => {
                               },
                               isLoading = false,
                               shouldDisableSubmit = false,
                               resetFilters = () => {
                               },
                               queryParams,
                               additionalFilters,
                               setQueryParams = () => {
                               },
                               bookingDesks,
                               runExport,
                               fetchCompany,
                               companyAccount,
                             }) => {
  const [companyMultiSelectKey, setCompanyMultiSelectKey] = useState(`companyMultiSelect`);
  const [agencyMultiSelectKey, setAgencyMultiSelectKey] = useState(`agencyMultiSelectKey`);
  const [company, setCompany] = useState();
  const [bookingDesk, setBookingDesk] = useState();
  const [selectedAgency, setSelectedAgency] = useState();

  useEffect(() => {
    if (company && fetchCompany) {
      fetchCompany(company, token);
    }
  }, [company]);

  const getAgenciesOptions = useMemo(() => {
    if (!agencies || (agencies && Object.keys(agencies).length === 0)) return [];
    if (!isRoleEdgeAdmin) return [];
    return agencies
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(agency => ({ label: agency.name, value: agency.id, text: agency.name }));
  }, [agencies, isRoleEdgeAdmin]);

  const getCompaniesOptions = useMemo(() => {
    if (!companies || (companies && Object.keys(companies).length === 0)) return [];
    return companies
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(company => ({
        label: `${company.name} - ${company.code} - ${company?.agency?.name}`,
        text: `${company.name} - ${company.code} - ${company?.agency?.name}`,
        value: company.id,
      }));
  }, [companies]);

  const handleCompanySelect = useCallback(e => {
    const params = e ? e.map(com => com.value) : [];
    setCompany(params);
    setQueryParams({ company: params });
    window.localStorage.setItem("companyParams", params);
  }, [setQueryParams]);

  const handleAgencySelect = useCallback(e => {
    const params = e ? e.map(com => com.value) : [];
    setSelectedAgency(params);
    setCompany(null);
    if (params) {
      fetchCompanies(token, null, { agency: params }, true);
    }
    setQueryParams({ agency: params, company: null });
  }, [setQueryParams]);

  const handleBookingDeskSelect = useCallback((e) => {
    let params = [];
    if (e) {
      e.map(desk => {
        params.push(desk.value);
      });
    }
    setBookingDesk(params);
    setQueryParams({ bookingDesk: params });
  }, [setBookingDesk]);

  const resetSelects = () => {
    setSelectedAgency(null);
    setCompany(null);
    setCompanyMultiSelectKey((prevKey) => prevKey + 1);
    setAgencyMultiSelectKey((prevKey) => prevKey + 1);
  };

  const getBookingDeskOptions = () => {
    let options = [];
    if (companyAccount && companyAccount?.corporate_account?.booking_desks && Object.keys(companyAccount.corporate_account.booking_desks).length > 0) {
      let desks = [];
      Object.values(companyAccount?.corporate_account?.booking_desks).forEach((desk) => {
        desks = desks.concat(desk);
      });
      desks.sort((a, b) => a.name.localeCompare(b.name)).map(bookingDesk => {
        options.push({
          text: bookingDesk.name,
          value: bookingDesk.id,
          label: bookingDesk.name,
        });
      });
    }
    return options;
  };

  return (
    <div className="flex flex-col lg:flex-wrap lg:flex-row justify-between align-bottom p-3 bg-white lg:items-end">
      {additionalFilters && <>{additionalFilters}</>}
      {getAgenciesOptions.length > 0 && (
        <div className="mb-2 xxl:mb-0 mr-2">
          <MultiSelect
            key={agencyMultiSelectKey}
            name="agency"
            label="Agency"
            className="mb-0"
            isClearable
            allowSelectAll={true}
            isMulti
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            options={getAgenciesOptions}
            onChange={handleAgencySelect}
            components={{ ValueContainer, Option, MultiValue }}
            value={getAgenciesOptions.filter(option => selectedAgency && selectedAgency.includes(option.value))}
          />
        </div>
      )}
      {getCompaniesOptions.length > 0 && (
        <div className="mb-2 xxl:mb-0 mr-2">
          <MultiSelect
            key={companyMultiSelectKey}
            name="company"
            label="Company"
            className="mb-0"
            isClearable
            allowSelectAll={true}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            options={getCompaniesOptions}
            onChange={handleCompanySelect}
            components={{ ValueContainer, Option, MultiValue }}
            value={getCompaniesOptions.filter(option => company && company.includes(option.value))}
          />
        </div>
      )}
      {getBookingDeskOptions().length > 0 && (
        <div className="mb-2 xxl:mb-0 mr-2">
          <MultiSelect
            name="booking_desk"
            label="Filter by Booking Desk"
            isMulti
            options={getBookingDeskOptions()}
            closeMenuOnSelect={true}
            hideSelectedOptions={false}
            wrapperClassName="mb-0"
            onChange={e => handleBookingDeskSelect(e)}
            autoComplete={"off"}
            components={{
              Option,
              MultiValue,
              ValueContainer,
            }}
            style={{
              searchBox: {
                "border-radius": "0px",
              },
            }}
          />
        </div>
      )}
      <div className="flex mb-2">
        <ButtonPrimary disabled={shouldDisableSubmit} onClick={runReport}>
          {isLoading && <Loading />}
          Run Report
        </ButtonPrimary>
        <ButtonGrey onClick={() => {
          resetFilters();
          resetSelects();
        }} classes={"ml-2"}>Reset</ButtonGrey>
      </div>
    </div>
  );
};

export default AgencyReportFilters;
